<section>
    <div class="style-operations">
      <div class="card style-card">        
            <div class="card-body">
                <ul class="list-group">
                    <li class="list-group-item list-group-item-primary">
                        Frações
                        <button type="button" class="btn btn-primary btn-sm style-btn" (click)="remember()">
                            <i class="fas fa-question"></i>
                        </button>
                        <button type="button" class="btn btn-primary btn-sm style-btn" (click)="calc()">
                            <i class="fas fa-chart-pie"></i>
                        </button>
                    </li>
                    <li class="list-group-item list-group-item-primary">
                        Soma
                        <button type="button" class="btn btn-primary btn-sm style-btn" (click)="remember()">
                            <i class="fas fa-question"></i>
                        </button>
                        <button type="button" class="btn btn-primary btn-sm style-btn" (click)="plus()">
                            <i class="fas fa-plus"></i>
                        </button>
                    </li>
                    <li class="list-group-item list-group-item-primary">
                        Subtração
                        <button type="button" class="btn btn-primary btn-sm style-btn" (click)="remember()">
                            <i class="fas fa-question"></i>
                        </button>
                        <button type="button" class="btn btn-primary btn-sm style-btn" (click)="minus()">
                            <i class="fas fa-minus"></i>
                        </button>
                    </li>
                    <li class="list-group-item list-group-item-primary">
                        Multiplicação
                        <button type="button" class="btn btn-primary btn-sm style-btn" (click)="remember()">
                            <i class="fas fa-question"></i>
                        </button>
                        <button type="button" class="btn btn-primary btn-sm style-btn" (click)="times()">
                            <i class="fas fa-times"></i>
                        </button>
                    </li>
                    <li class="list-group-item list-group-item-primary">
                        Divisão
                        <button type="button" class="btn btn-primary btn-sm style-btn" (click)="remember()">
                            <i class="fas fa-question"></i>
                        </button>
                        <button type="button" class="btn btn-primary btn-sm style-btn" (click)="divide()">
                            <i class="fas fa-divide"></i>
                        </button>
                    </li>
                </ul>                
            </div>                       
      </div>
    </div>
</section>
