<section>
    <div class="container style-contact">
      <div class="card style-card">        
          <div class="card-body">
            <h4 class="card-title style-title">Contatos</h4>
            <p class="style-text">
                    Para qualquer dúvida, sugestões, reclamações ou elogios, favor entrar em contato.
                    Não deixe de deixar seu comentário nas lojas de aplicativos.
            </p><br>
            <p class="style-text">
                <b>Fale Conosco:</b><br><br>
                <span>
                    <i class="far fa-envelope"></i> E-mail:<br>
                </span> contatos@calcfraction.orkneytech.com.br <br><br>
                <span>
                    <i class="fas fa-globe-americas"></i> Site:<br>
                </span> 
                    <a href="https://calcfraction.orkneytech.com.br" alt="Site" target="_blank">                        
                    </a>https://calcfraction.orkneytech.com.br
            </p>                                  
            
          </div>                        
      </div>
    </div>
</section>
