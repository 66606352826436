<section>
    <div class="container style-about">
      <div class="card style-card">        
          <div class="card-body">
            <h4 class="card-title style-title">Sobre o Aplicativo</h4>
            <p class="style-text">
                    Este aplicativo foi desenvolvido pelo LAMID para a disciplina de 
                    EGC - 510046 - Compartilhamento de Conhecimento e Acessibilidade Web.
                    Trata-se de um simulador para operações com frações.
            </p><br>
            <p class="style-text">
                <b>Tecnologia:</b><br>
                Esta aplicação foi desenvolvida com tecnologia Angular 9+, Nodejs, Sass, Typescript e Apache Cordova.
            </p><br>            
            <p class="style-text">
                <b>Autores:</b><br>
                <b>Desenvolvedor:</b> Ivam Galvão Filho.<br>
                <b>Colaborador:</b> Maurício Perin.
            </p>                                  
            
          </div>                        
      </div>
    </div>
</section>

