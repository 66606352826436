<section>
    <div class="row style-footer">
      <div class="col-3"><a href="#" alt="Termos" routerLink="about"><i class="fas fa-mobile-alt"></i></a><span class="style-text">Sobre</span></div>
      <div class="col-3"><a href="https://calcfraction.orkneytech.com.br" alt="Site" target="_blank"><i class="fas fa-globe-americas"></i></a><span class="style-text">Site</span></div>
      <div class="col-3"><a href="#" alt="Termos" routerLink="terms"><i class="far fa-file-alt"></i></a><span class="style-text">Termos</span></div>
      <div class="col-3"><a href="#" alt="Contatos" routerLink="contact"><i class="far fa-envelope"></i></a><span class="style-text">Contatos</span></div>
    </div>
    <div>
      <p class="style-footer style-text2"><i>Powered By Angular 9+.</i></p>
    </div>
</section>
