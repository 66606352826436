import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-arithmetic',
  templateUrl: './arithmetic.component.html',
  styleUrls: ['./arithmetic.component.scss']
})
export class ArithmeticComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
