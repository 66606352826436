<section>
    <nav class="navbar navbar-expand-md bg-dark navbar-dark style-nav">
      <!-- Brand -->
      <a class="navbar-brand" href="#">App CalcFraction</a>
  
      <!-- Toggler/collapsibe Button -->
      <button class="navbar-toggler" id="btn-collapse" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
        <span class="navbar-toggler-icon"></span>
      </button>
  
      <!-- Navbar links -->
      <div class="collapse navbar-collapse" id="collapsibleNavbar">
        <ul class="navbar-nav">
          <li class="nav-item">
            <a class="nav-link" href="#" routerLink="home">Home</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#" routerLink="operations">Operações</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#" routerLink="theory">Material</a>
          </li>          
          <li class="nav-item">
            <a class="nav-link" href="#" routerLink="about">Sobre</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="https://calcfraction.orkneytech.com.br/" target="_blank">Site</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#" routerLink="terms">Termos</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#" routerLink="contact">Contatos</a>
          </li>
        </ul>
      </div>
    </nav>
  </section>
