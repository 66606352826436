<section>
    <div class="container style-terms">
      <div class="card style-card">        
          <div class="card-body">
            <h4 class="card-title style-title">Termos de Uso</h4>            
                <div id="accordion">

                    <div class="card">
                      <div class="card-header">
                        <a class="card-link" data-toggle="collapse" href="#collapse1">
                            1. ACEITAÇÃO:
                        </a>
                      </div>
                      <div id="collapse1" class="collapse show" data-parent="#accordion">
                        <div class="card-body style-card-text">
                            Este é um contrato firmado entre você, 
                            de agora em diante denominado como usuário, e o Lamid. 
                            Este “Termo de Uso de Aplicativo” 
                            rege o uso de todos os aplicativos disponibilizados 
                            gratuitamente pelo Lamid sejam para dispositivos móveis 
                            (Android, IOS, Windows Mobile), servidores, 
                            computadores pessoais (desktops) ou serviços web. 
                            Se você não concordar com estes termos não use este aplicativo. 
                            Você reconhece ainda que analisou e aceitou as condições de uso. 
                            Leia-as atentamente pois o uso deste aplicativo 
                            significa que você aceitou todos os termos e concorda em cumpri-los. 
                            Se você, usuário, for menor de idade ou declarado incapaz em quaisquer aspectos, 
                            precisará da permissão de seus pais ou responsáveis que 
                            também deverão concordar com estes mesmos termos e condições.
                        </div>
                      </div>
                    </div>
                  
                    <div class="card">
                      <div class="card-header">
                        <a class="collapsed card-link" data-toggle="collapse" href="#collapse2">
                            2. LICENÇA LIMITADA:
                        </a>
                      </div>
                      <div id="collapse2" class="collapse" data-parent="#accordion">
                        <div class="card-body style-card-text">
                            Você recebeu uma licença limitada, não transferível, não exclusiva, 
                            livre de royalties e revogável para baixar, instalar, 
                            executar e utilizar este aplicativo em seu dispositivo. 
                            Você reconhece e concorda que a Lamid concede ao usuário 
                            uma licença exclusiva para uso e desta forma não lhe transfere 
                            os direitos sobre o produto. O aplicativo deverá ser utilizado por você, 
                            usuário. A venda, transferência, modificação, 
                            engenharia reversa ou distribuição bem como a cópia de textos, 
                            imagens ou quaisquer partes nele contido é expressamente proibida.
                        </div>
                      </div>
                    </div>
                  
                    <div class="card">
                      <div class="card-header">
                        <a class="collapsed card-link" data-toggle="collapse" href="#collapse3">
                            3. ALTERAÇÕES, MODIFICAÇÕES E RESCISÃO
                        </a>
                      </div>
                      <div id="collapse3" class="collapse" data-parent="#accordion">
                        <div class="card-body style-card-text">
                            O Lamid reserva-se no direito de, a qualquer tempo, 
                            modificar estes termos seja incluindo, 
                            removendo ou alterando quaisquer de suas cláusulas. 
                            Tais modificações terão efeito imediato. 
                            Após publicadas tais alterações, ao continuar com o uso do aplicativo, 
                            você terá aceitado e concordado em cumprir os termos modificados. 
                            A Lamid pode, de tempos em tempos, 
                            modificar ou descontinuar (temporária ou permanentemente) 
                            a distribuição ou a atualização deste aplicativo. 
                            O Lamid não é obrigada a fornecer nenhum serviço de suporte 
                            para este aplicativo. 
                            O usuário não poderá responsabilizar a Lamid nem seus diretores, 
                            executivos, funcionários, afiliados, agentes, 
                            contratados ou licenciadores por quaisquer modificações, 
                            suspensões ou descontinuidade do aplicativo. 
                        </div>
                      </div>
                    </div>

                    <div class="card">
                        <div class="card-header">
                          <a class="collapsed card-link" data-toggle="collapse" href="#collapse4">
                            4. COLETA E USO DE DADOS
                          </a>
                        </div>
                        <div id="collapse4" class="collapse" data-parent="#accordion">
                          <div class="card-body style-card-text">
                            Você concorda que a Lamid pode coletar e usar dados técnicos de seu 
                            dispositivo tais como especificações, configurações, 
                            versões de sistema operacional, tipo de conexão à internet e afins, 
                            somente com o intuito de melhorar a aplicação para versões futuras. 
                            O Lamid não coletará, nem utilizará seus dados pessoais.
                          </div>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-header">
                            <a class="collapsed card-link" data-toggle="collapse" href="#collapse5">
                                5. ISENÇÃO DE GARANTIAS E LIMITAÇÕES DE RESPONSABILIDADE 
                            </a>
                        </div>
                        <div id="collapse5" class="collapse" data-parent="#accordion">
                            <div class="card-body style-card-text">
                                Este aplicativo estará em contínuo desenvolvimento e pode conter 
                                erros e, por isso, o uso é fornecido "no estado em que se encontra" 
                                e sob risco do usuário final. 
                                Na extensão máxima permitida pela legislação aplicável a Lamid e 
                                seus fornecedores isentam-se de quaisquer garantias e condições 
                                expressas ou implícitas incluindo, sem limitação, 
                                garantias de comercialização, adequação a um propósito específico, 
                                titularidade e não violação no que diz respeito ao aplicativo e 
                                qualquer um de seus componentes ou ainda à prestação ou não de 
                                serviços de suporte. 
                                O Lamid não garante que a operação deste aplicativo seja contínua 
                                e sem defeitos. Exceto pelo estabelecido neste documento não há 
                                outras garantias, condições ou promessas aos aplicativos, 
                                expressas ou implícitas, e todas essas garantias, 
                                condições e promessas podem ser excluídas de acordo com o que é 
                                permitido por lei sem prejuízo à Lamid e seus colaboradores.
                            </div>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-header">
                            <a class="collapsed card-link" data-toggle="collapse" href="#collapse6">
                                Item I
                            </a>
                        </div>
                        <div id="collapse6" class="collapse" data-parent="#accordion">
                            <div class="card-body style-card-text">
                                I. O Lamid não garante, declara ou assegura que o uso deste 
                                aplicativo será ininterrupto ou livre de erros e você concorda 
                                que a Lamid poderá remover por períodos indefinidos ou cancelar 
                                este aplicativo a qualquer momento sem que você seja avisado. 
                            </div>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-header">
                            <a class="collapsed card-link" data-toggle="collapse" href="#collapse7">
                                Item II
                            </a>
                        </div>
                        <div id="collapse7" class="collapse" data-parent="#accordion">
                            <div class="card-body style-card-text">
                                II. O Lamid não garante, declara nem assegura que este aplicativo 
                                esteja livre de perda, interrupção, ataque, vírus, interferência, 
                                pirataria ou outra invasão de segurança e isenta-se de qualquer 
                                responsabilidade em relação à essas questões. 
                                Você é responsável pelo backup do seu próprio dispositivo. 
                            </div>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-header">
                            <a class="collapsed card-link" data-toggle="collapse" href="#collapse8">
                                Item III
                            </a>
                        </div>
                        <div id="collapse8" class="collapse" data-parent="#accordion">
                            <div class="card-body style-card-text">
                                III. Em hipótese alguma o Lamid, bem como seus diretores, executivos, 
                                funcionários, afiliadas, agentes, contratados ou licenciadores 
                                responsabilizar-se-ão por perdas ou danos causados pelo uso do aplicativo. 
                            </div>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-header">
                            <a class="collapsed card-link" data-toggle="collapse" href="#collapse9">
                                Item IV
                            </a>
                        </div>
                        <div id="collapse9" class="collapse" data-parent="#accordion">
                            <div class="card-body style-card-text">
                                IV. O Lamid isenta-se de qualquer responsabilidade direta ou 
                                indireta sobre estes conteúdos e o acesso é facultativo ao usuário.
                            </div>
                        </div>
                    </div>
                  
                  </div>
                <br>
                <p class="style-card-text"><strong>Obs.:</strong> Esta aplicação web/mobile foi desenvolvido utilizando estrutura 
                    tecnológica fornecida pela OrkneyTech para o LAMID.                    
                </p><br>
                <p>
                    <strong>Autor:</strong> Analista desenvolvedor Ivam Galvão Filho.                    
                </p><br>
                <p>
                    <strong>Data Atualização:</strong>Florianópolis, 11 de outubro de 2020.
                </p>
          </div>                        
      </div>
    </div>
</section>

