<section>
    <div class="container style-theory">
      <div class="card style-card">        
          <div class="card-body">
            <h3>Frações</h3><br>  
            <div id="accordion">

                <div class="card">
                  <div class="card-header">
                    <a class="card-link" data-toggle="collapse" href="#collapseOne">
                        Definição:
                    </a>
                  </div>
                  <div id="collapseOne" class="collapse show" data-parent="#accordion">
                    <div class="card-body">
                        Uma fração representa a razão entre dois números inteiros.
                    </div>
                  </div>
                </div>
              
                <div class="card">
                  <div class="card-header">
                    <a class="collapsed card-link" data-toggle="collapse" href="#collapseTwo">
                        Tipos:
                    </a>
                  </div>
                  <div id="collapseTwo" class="collapse" data-parent="#accordion">
                    <div class="card-body">
                        <strong>1.Frações Próprias:</strong> Frações em que o numerador é menor que o denominador.<br>
                        <strong>2.Frações Impróprias:</strong> Frações em que o numerador é maior que o denominador.<br>
                        <strong>3.Fração Aparente:</strong> Fração em que o numerador é múltiplo do denominador.<br>
                        <strong>4.Fração Mista:</strong> É constituída por uma parte inteira e uma parte 
                        fracionária representada por números mistos.
                    </div>
                  </div>
                </div>           
              
              </div>
          </div>                        
      </div>
    </div>
</section>


