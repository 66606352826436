<!--The content below is only a placeholder and can be replaced.-->
<!-- Tag call nav.component -->
<div>
  <app-header></app-header>
</div>
<!-- Tag call nav.component -->

<!-- Tag call home.component -->
<div>
  <router-outlet></router-outlet>
</div>
<!-- Tag call home.component -->

<!-- Tag call footer.component -->
<div>
  <app-footer></app-footer>
</div>
<!-- Tag call footer.component -->