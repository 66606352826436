<section>
    <div class="container style-dev">      
      <h4 class="card-title style-text style-log">Página em Desenvolvimento</h4>            
      <span class="style-icon">
        <button class="btn btn-link">
            <i class="fas fa-exclamation-triangle fa-7x"></i>
        </button>                
      </span>         

    </div>
</section>
