<section>
    <div class="container style-terms">
      <div class="card style-card">        
          <div class="card-body">
            <h4 class="card-title style-title">Relembrando</h4><br>
            <p>Com o <strong>CalcFraction</strong> você pode realizar operações de adição, subtração, multiplicação e divisão
                de frações matemáticas.</p><br>            
                <div id="accordion">

                    <div class="card">
                      <div class="card-header">
                        <a class="card-link" data-toggle="collapse" href="#collapse1">
                            1. Adição:
                        </a>
                      </div>
                      <div id="collapse1" class="collapse show" data-parent="#accordion">
                        <div class="card-body style-card-text">
                            <p>Adição é uma ação de adicionar, incluir ou acrescentar alguma coisa.</p>
                            <p>
                                <strong>Exemplo:</strong> Pedimos uma pizza pela serviço de tele entrega, ao pedido da pizza foi SOMADO, o
                                valor da entrega, então neste caso o valor que vamos pagar será o da pizza mais o da entrega.  
                            </p>
                        </div>
                      </div>
                    </div>
                  
                    <div class="card">
                      <div class="card-header">
                        <a class="collapsed card-link" data-toggle="collapse" href="#collapse2">
                            2. Subtração:
                        </a>
                      </div>
                      <div id="collapse2" class="collapse" data-parent="#accordion">
                        <div class="card-body style-card-text">
                            <p>Subtração é uma técnica usada para diminuir, retirar ou excluir um número X de itens a fim
                                de obter sua diferença ou resto.</p>
                            <p>
                                <strong>Exemplo:</strong> Compramos um ingresso de cinema pela internet, os serviços realizados pelo site da
                                empresa no mês de lançamento do site, como forma de estímulo ao uso do serviço on-line, estão
                                oferecendo um desconto de 10% (dez por cento) para as compras realizadas por ele. Neste caso
                                ao valor do ingresso comprado de forma on-line será SUBTRAÍDO 10% (dez por cento) do valor
                                do ingresso referente ao desconto anunciado.  
                            </p>
                        </div>
                      </div>
                    </div>
                  
                    <div class="card">
                      <div class="card-header">
                        <a class="collapsed card-link" data-toggle="collapse" href="#collapse3">
                            3. Multiplicação
                        </a>
                      </div>
                      <div id="collapse3" class="collapse" data-parent="#accordion">
                        <div class="card-body style-card-text">
                            <p>Multiplicação é uma forma simples de se adicionar uma quantidade finita de números iguais.
                                O resultado da multiplicação de dois números é conhecido como produto. </p>
                            <p>
                                <strong>Exemplo:</strong>  João ganha R$ 50,00 (cinquenta reais) de mesada por mês. João decidiu guardar esse
                                dinheiro por dois anos, sendo assim, quanto dinheiro João terá daqui a dois anos? Para isso bata
                                realizarmos a seguinte conta: considerando que cada ano tem 12 meses, podemos
                                MULTIPLICAR 12 meses por 2 anos e teremos 24 meses, agora MULTIPLICAMOS os R$ 50,00
                                (cinquenta reais) por 24 meses, logo João terá R$ 1.200,00 (um mil e duzentos reais).  
                            </p> 
                        </div>
                      </div>
                    </div>

                    <div class="card">
                        <div class="card-header">
                          <a class="collapsed card-link" data-toggle="collapse" href="#collapse4">
                            4. Divisão
                          </a>
                        </div>
                        <div id="collapse4" class="collapse" data-parent="#accordion">
                          <div class="card-body style-card-text">
                            <p>Divisão é o ato de dividir um número X em partes iguais para todos.</p>
                            <p>
                                <strong>Exemplo:</strong>  Ricardo comprou um tênis parcelado no cartão de crédito, o valor do tênis foi
                                DIVIDIDO em 10(dez) vezes.  
                            </p>
                          </div>
                        </div>
                    </div>
                    
                    <div class="card">
                        <div class="card-header">
                          <a class="collapsed card-link" data-toggle="collapse" href="#collapse4">
                            5. Observações
                          </a>
                        </div>
                        <div id="collapse4" class="collapse" data-parent="#accordion">
                          <div class="card-body style-card-text">
                            <p>
                                Com relação ao cálculo com frações devemos lembrar que elas pertencem ao conjunto
                                dos números racionais, dizemos que todo número escrito da forma X/Y é um número
                                racional, sendo X e Y são números inteiros e Y precisa ser diferente de 0 (zero).   
                            </p>
                          </div>
                        </div>
                    </div>
                    
                  </div>
                <br>
                
          </div>                        
      </div>
    </div>
</section>


